import React, { useState } from "react";
import "./styles/TermsOfUse.scss";

const TermsOfUse = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="terms-of-use-container">
      <h2>利用規約</h2>

      <div className="terms-box">
        <p>
          利用規約
          <br />

          *第1条（目的）*<br />

          本利用規約（以下「本規約」といいます。）は、株式会社Tensais. （以下「当社」といいます。）が提供するビジネスマッチングサービス「COMY」（以下「本サービス」といいます。）の利用条件を定めるものです。<br />
          本サービスを利用するすべてのユーザー（以下「ユーザー」といいます。）は、本規約に同意した上で本サービスを利用します。<br />
          ユーザーが本サービスを利用した時点で、本規約に同意したものとみなされます。<br /><br />

          *第2条（定義）*<br />
          本規約において使用する用語の定義は次の通りです。<br />
          (1) 「ユーザー」とは、本サービスの利用登録をしたすべての者を指します。<br />
          (2) 「登録情報」とは、ユーザーが本サービスを利用するために当社に提供した情報を指します。<br />
          (3) 「本サービス」とは、当社が提供するビジネスマッチング支援サービスを指します。<br /><br />

          *第3条（ユーザー登録）*<br />
          ユーザー登録を希望する者は、本規約に同意し、当社が定める方法に従ってクレジットカードによる年会費支払い手続きを完了することで、ユーザー登録を行います。<br />
          ユーザー登録には年会費として13,200円（税込）が必要です。無料会員登録は存在しません。<br />
          登録情報に変更が生じた場合、ユーザーは速やかに当社に通知し、登録情報を更新する必要があります。<br />
          当社は、独自の判断によりユーザー登録を拒否することができ、理由の説明義務を負いません。<br /><br />

          *第4条（本サービスの内容）*<br />
          本サービスは、ビジネスマッチングの支援を行うものであり、ユーザーが自己のビジネスパートナーを見つけ、ビジネスを拡大することを目的としています。<br />
          本サービスは有料会員のみが利用でき、年会費13,200円（税込）が発生します。<br /><br />

          *第5条（利用料金と支払方法）*<br />
          本サービスの利用には、年会費として13,200円（税込）が必要です。途中解約をしても、年会費の返金は一切行いません。<br />
          支払いは、会員登録時にクレジットカード決済により行われます。<br /><br />

          *第6条（ユーザー情報の管理）*<br />
          ユーザーは、自己の責任において、本サービスに必要な機器や通信手段を整備するものとし、利用に係る費用を負担します。<br />
          ユーザー情報の管理はユーザーの責任で行うものとし、第三者の不正利用等による損害については当社は責任を負いません。<br /><br />

          *第7条（サービス内容の変更・中断・終了）*<br />
          当社は、ユーザーに通知することなく、本サービスの内容を変更、停止または終了することができます。ただし、重大な変更がある場合には、事前に通知します。<br />
          サービスの停止または終了によって生じた損害について、当社は一切の責任を負いません。<br /><br />

          *第8条（知的財産権）*<br />
          本サービスに関する著作権、商標権、その他の知的財産権は、すべて当社または正当な権利者に帰属します。<br />
          ユーザーは、本サービスを通じて得た情報を許可なく第三者に提供、販売、公開してはなりません。<br /><br />

          *第9条（禁止事項）*<br />
          ユーザーは、本サービスの利用に際して以下の行為を禁止します。<br />
          (1) 法令に違反する行為<br />
          (2) 他のユーザーや第三者の権利を侵害する行為<br />
          (3) サービスの運営を妨害する行為<br /><br />

          *第10条（会員資格の停止・解除）*<br />
          当社は、ユーザーが以下のいずれかに該当する場合、事前通知なしに会員資格を停止または解除できるものとします。<br />
          利用規約に違反した場合<br />
          当社の信用を著しく損なう行為を行った場合<br />
          不正な手段でサービスを利用した場合<br /><br />

          *第11条（免責事項）*<br />
          当社は、本サービスの提供において、その完全性や正確性を保証しません。<br />
          本サービスの利用に関連して発生する、以下の損害について当社は一切責任を負いません。<br />
          通信環境やインターネット接続の障害に起因する損害<br />
          ユーザー間のトラブルや紛争に関する損害<br />
          天災や不可抗力による損害<br /><br />

          *第12条（プライバシーポリシー）*<br />
          本サービスにおけるユーザーの個人情報は、当社が別途定める「プライバシーポリシー」に基づき適正に取り扱います。<br /><br />
          *第13条（クレーム対応・問い合わせ）*<br />
          ユーザーからの問い合わせやクレームに関しては、当社が定める窓口を通じて対応します。対応時間は平日9:00～17:00です。<br /><br />

          *第14条（準拠法及び管轄裁判所）*<br />
          本規約の解釈には日本法が適用されます。<br />
          本サービスに関する紛争は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。<br /><br />

          株式会社Tensais.<br />
          代表取締役　佐野 真吾<br /><br />

          〒151-0053 東京都渋谷区代々木1-21-3<br />
          Mail:info@comy.jp<br /><br />

          2024年10月31日
        </p>
      </div>

      <div className="checkbox-container">
        <input
          type="checkbox"
          id="agree"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="agree">利用規約に同意する</label>
      </div>

      <button
        type="button"
        disabled={!isChecked}
        className={isChecked ? "enabled" : "disabled"}
        onClick={() => {
          window.location.href = "/stripe-payment";
        }}
      >
        次へ
      </button>
    </div>
  );
};

export default TermsOfUse;
